import Vue from 'vue'

export default Vue.extend({
  name: 'XpProgressBar',
  props: {
    color: {
      type: String
    },
    height: {
      type: String,
      default: '5px'
    },
    value: {
      type: Number,
      default: null
    }
  },
  render (h) {
    return h('div', {
      class: 'c-xp-progress-bar',
      style: { height: this.height, color: this.color }
    }, [
      h('div', { class: 'c-xp-progress-bar__line' }),
      h('div', { class: 'c-xp-progress-bar__subline c-xp-progress-bar__subline--inc' }),
      h('div', { class: 'c-xp-progress-bar__subline c-xp-progress-bar__subline--dec' }),
      h('div', { class: 'c-xp-progress-bar__subline c-xp-progress-bar__subline--actual', style: `width: ${this.value}%` })
    ])
  }
})
